import type { App } from "vue"

export interface IReloadPlugin {
  onReload: (beforeUnloadListener: (event: any) => void) => void
}

export default {
  install: (app: App) => {
    const reloadModule: IReloadPlugin = {
      onReload: function (beforeUnloadListener: (event: any) => void) {
        window.addEventListener("beforeunload", beforeUnloadListener, { capture: true })
      },
    }
    app.config.globalProperties.$reload = reloadModule
  },
}

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $reload: IReloadPlugin
  }
}
