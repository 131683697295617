import type { AlertType } from "@/static/Alert"
import { useAlertStore } from "@/stores/alert"
import type { App } from "vue"

export interface IAlertPlugin {
  push: (type: AlertType, content: string, title?: string) => void
}

export default {
  install: (app: App) => {
    const alertModule: IAlertPlugin = {
      push: function (type: AlertType, content: string, title?: string) {
        const alert = useAlertStore()
        alert.push(type, content, title)
      },
    }
    app.config.globalProperties.$alert = alertModule
  },
}

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $alert: IAlertPlugin
  }
}
