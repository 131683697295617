import { useAuthenticationStore } from "../stores/authentication"
import { UserRole } from "../static/User"

export default () => {
  const auth = useAuthenticationStore()
  if (auth.user?.role.code !== UserRole.admin) {
    return {
      success: false,
      path: "/error",
    }
  }
  return {
    success: true,
  }
}
