import { useAuthenticationStore } from "../stores/authentication"

export default () => {
  const auth = useAuthenticationStore()

  if (!(auth.user && auth.user?.email)) {
    return {
      success: false,
      path: "/authentication",
    }
  }
  return {
    success: true,
  }
}
