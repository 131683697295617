<template>
  <div></div>
</template>
<script lang="ts">
import { defineComponent } from "vue"
import { useRegisterSW } from "virtual:pwa-register/vue"
import PushDataService from "@/services/PushDataService"
import { useAuthenticationStore } from "@/stores/authentication"
import { mapStores } from "pinia"
const { updateServiceWorker } = useRegisterSW()
export default defineComponent({
  name: "ReloadPWA",
  setup() {
    const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW()
    const close = async () => {
      offlineReady.value = false
      needRefresh.value = false
    }
    return { offlineReady, needRefresh, updateServiceWorker, close }
  },
  computed: {
    ...mapStores(useAuthenticationStore),
    user() {
      return this.authenticationStore.user
    },
  },
  methods: {
    async close() {
      this.offlineReady.value = false
      this.needRefresh.value = false
    },
    async updateServiceWorker() {
      await updateServiceWorker()
    },
    initSW() {
      if (navigator.serviceWorker === undefined) {
        //service worker isn't supported
        return
      }

      //don't use it here if you use service worker
      //for other stuff.
      if (window.PushManager === undefined) {
        //push isn't supported
        return
      }

      //register the service worker
      navigator.serviceWorker.register("/serviceWorker.js", { scope: location.origin }).then(() => {
        this.initPush()
      })
    },
    initPush() {
      if (!navigator.serviceWorker.ready || typeof Notification === 'undefined') {
        return
      }

      new Promise(function (resolve, reject) {
        const permissionResult = Notification.requestPermission(function (result) {
          resolve(result)
        })

        if (permissionResult) {
          permissionResult.then(resolve, reject)
        }
      }).then((permissionResult) => {
        if (permissionResult === "granted") {
          this.subscribeUser()
        }
      })
    },
    subscribeUser() {
      navigator.serviceWorker.ready
        .then((registration) => {
          const subscribeOptions = {
            userVisibleOnly: true,
            applicationServerKey: this.urlBase64ToUint8Array(import.meta.env.VITE_VAPID_PUBLIC_KEY),
          }

          return registration.pushManager.subscribe(subscribeOptions)
        })
        .then((pushSubscription) => {
          PushDataService.store(pushSubscription)
        })
    },
    urlBase64ToUint8Array(base64String: string) {
      let padding = "=".repeat((4 - (base64String.length % 4)) % 4)
      let base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/")

      let rawData = window.atob(base64)
      let outputArray = new Uint8Array(rawData.length)

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
      }
      return outputArray
    },
  },
  watch: {
    needRefresh() {
      if (this.needRefresh) {
        this.updateServiceWorker()
      }
    },
    user(newValue, oldValue) {
      if (this.user !== null && this.user.id && newValue.id !== oldValue?.id) {
        setTimeout(this.initSW, 2000)
      }
    },
  },
})
</script>
