import type { AlertType } from "@/static/Alert"
import type IAlert from "@/types/Alert.types"
import { defineStore } from "pinia"

export const useAlertStore = defineStore("alert", {
  state: () => ({
    /** @type {IAlert[]} current alerts list to display */
    alerts: [] as IAlert[],
  }),

  actions: {
    /**
     * Add new alert toast in list
     * @param type
     * @param content
     */
    push(type: AlertType, content: string, title?: string) {
      const alert = {
        type: type,
        title: title,
        content: content,
      } as IAlert

      const find = this.alerts.find((a) => a.title === alert.title && a.content === alert.content)

      if (!find) {
        this.alerts.push(alert)
      }

      const timer = setTimeout(() => {
        this.pop(alert)
        clearTimeout(timer)
      }, 10000)
    },

    /**
     * Remove the given alert toast from list
     * @param alert
     */
    pop(alert: IAlert) {
      const index = this.alerts.findIndex((el) => el === alert)
      this.alerts.splice(index, 1)
    },
  },

  persist: {
    enabled: true,
  },
})
