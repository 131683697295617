/**
 * Get the svg HTMLElement from given type
 * @private
 * @param type
 * @param size
 * @returns {SVGElement} Svg path
 */
export function getSvg(type: string, size: string): SVGElement {
  const iconSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg")
  const iconPath = document.createElementNS("http://www.w3.org/2000/svg", "path")

  iconSvg.setAttribute("width", size)
  iconSvg.setAttribute("height", size)
  iconSvg.setAttribute("viewBox", "0 0 24 24")
  iconSvg.setAttribute("fill", "currentColor")
  iconSvg.setAttribute("color", "currentColor")
  iconSvg.classList.add("text-gray-700")

  iconPath.setAttribute("d", type)

  iconPath.setAttribute("fill-rule", "evenodd")
  iconPath.setAttribute("clip-rule", "evenodd")

  iconSvg.appendChild(iconPath)

  return iconSvg
}

export function regexIsUrl(url: string) {
  const urlRegex = new RegExp(
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i
  )

  return urlRegex.test(url)
}
