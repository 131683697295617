import http from "@/http-common"
import type { ConfigurationState } from "@/static/Configuration"
import type { IAccountForm } from "@/types/Account.types"

class AccountDataService {
  /**
   * Create new account + new user
   * @param form
   * @param contactToken
   * @param huboAccountId
   * @returns
   */
  createAccount(form: IAccountForm, contactToken = null, huboAccountId: null): Promise<any> {
    return http.post(`/accounts/create`, {
      user: form,
      contactToken: contactToken,
      huboAccountId: huboAccountId
    })
  }

  /**
   * Update the current authenticated user account configuration state
   * @param id
   * @param state
   * @returns
   */
  updateConfigurationState(id: Number, state: ConfigurationState): Promise<any> {
    return http.post(`/accounts/configuration-state/update`, {
      accountId: id,
      state: state,
    })
  }

  /**
   * Set the configured param to finish the configuration mode
   * @param id
   * @param configured
   * @returns
   */
  setAccountConfigured(id: Number, configured: boolean): Promise<any> {
    return http.post(`/accounts/update-configured`, {
      account_id: id,
      configured: configured,
    })
  }

  /**
   * Get the app available access features
   * @param headers
   * @returns
   */
  features(headers: {}): Promise<any> {
    return http.get(`/auth/access-features`, { headers: headers })
  }

  /**
   * Get the current authenticated account establishments list
   * @param headers
   * @returns
   */
  establishments(headers: {}): Promise<any> {
    return http.get(`/auth/establishments`, { headers: headers })
  }

  /**
   * Return the establishment by the given id
   * @param id
   * @returns
   */
  establishmentById(id: number, headers: {}): Promise<any> {
    return http.get(`/auth/establishments/` + id, { headers: headers })
  }
}

export default new AccountDataService()
