import { useDiffuserStore } from "@/stores/diffuser"
import { useEstablishmentStore } from "@/stores/establishment"
import type { App } from "vue"
import type { RouteLocationNormalizedLoaded } from "vue-router"

export interface IDiffuserPlugin {
  initRouteQuery: (
    route: RouteLocationNormalizedLoaded,
    callback: (response: { success: Boolean; diffuserType?: string; connexionErrorCode?: string }) => void
  ) => void
}

export default {
  install: (app: App) => {
    const diffuserModule: IDiffuserPlugin = {
      initRouteQuery(
        route: RouteLocationNormalizedLoaded,
        callback: (response: { success: Boolean; diffuserType?: string; connexionErrorCode?: string }) => void
      ) {
        if (route.query && route.query.errorCode) {
          // Erreur de connexion à gg ou fb
          callback({
            success: false,
            diffuserType: route.params.diffuserType.toString(),
            connexionErrorCode: route.query.errorCode.toString().replace(".", "_"),
          })
        } else if (route.params.diffuserType) {
          // Validation de la connexion à gg ou fb
          const diffuserStore = useDiffuserStore()
          const establishmentStore = useEstablishmentStore()

          diffuserStore.check(
            establishmentStore.currentEstablishment.id,
            route.params.diffuserType.toString(),
            (isConnected: boolean) => {
              if (isConnected) {
                // this.showModalSuccess = true
                if (!establishmentStore.currentEstablishment.first_diffuser_connection) {
                  establishmentStore.setFirstConnexion()
                }

                callback({
                  success: true,
                })
              } else {
                // Erreur de connexion à gg ou fb
                callback({
                  success: false,
                  diffuserType: route.params.diffuserType.toString(),
                  connexionErrorCode: "default_connexion_error",
                })
              }
            }
          )
        }
      },
    }
    app.config.globalProperties.$diffuser = diffuserModule
  },
}

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $diffuser: IDiffuserPlugin
  }
}
