import http from "@/http-common"
import type { IFreemiumConvertTraffic, IFreemiumOnboardingTraffic } from "@/types/Tracking.types"

class TrackingDataService {
  createFreemiumTraffic(traffic: IFreemiumOnboardingTraffic | IFreemiumConvertTraffic): Promise<any> {
    return http.post(`/trackings/freemium-traffic/create`, traffic)
  }

  discoverPremium(): Promise<any>  {
    return http.get(`/auth/trackings/discover-premium`)
  }
}

export default new TrackingDataService()
