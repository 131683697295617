import {
  codePostalRegex,
  emailRegex,
  passwordContainsLetters,
  passwordContainsNumber,
  passwordContainsSpecials,
  passwordRegex,
  RegexType,
} from "@/static/RegexType"
import type { App } from "vue"

export interface IStringModule {
  validateStringByPattern: (name: string, type: RegexType) => boolean
  validatePasswordSize: (password: string) => boolean
  validatePasswordContainsLetters: (password: string) => boolean
  validatePasswordContainsNumber: (password: string) => boolean
  validatePasswordContainsSpecial: (password: string) => boolean
  convertToSlug: (text: String) => String
  convertSlugToString: (text: String) => String
  formatNumberToHumanString: (nb: number) => String
}

export default {
  install: (app: App) => {
    const stringModule: IStringModule = {
      validateStringByPattern: function (text: string, type: RegexType) {
        switch (type) {
          case RegexType.codePostal:
            return new RegExp(codePostalRegex).test(text)
          case RegexType.email:
            return new RegExp(emailRegex).test(text)
          case RegexType.password:
            return new RegExp(passwordRegex).test(text)
          case RegexType.passwordContainsLetters:
            return new RegExp(passwordContainsLetters).test(text)
          case RegexType.passwordContainsNumber:
            return new RegExp(passwordContainsNumber).test(text)
          case RegexType.passwordContainsSpecials:
            return new RegExp(passwordContainsSpecials).test(text)
          default:
            return false
        }
      },

      validatePasswordSize: function (password: string) {
        return password.length >= 8
      },

      validatePasswordContainsLetters: function (password: string) {
        return this.validateStringByPattern(password, RegexType.passwordContainsLetters)
      },

      validatePasswordContainsNumber: function (password: string) {
        return this.validateStringByPattern(password, RegexType.passwordContainsNumber)
      },

      validatePasswordContainsSpecial: function (password: string) {
        return this.validateStringByPattern(password, RegexType.passwordContainsSpecials)
      },

      convertToSlug: function (text: String): String {
        return text
          .toLowerCase()
          .replace(/ /g, "-")
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
      },

      convertSlugToString: function (text: String): String {
        return text.toUpperCase().replace(/-/, " ")
      },

      formatNumberToHumanString: function (nb: number) {
        // 2 decimal places => 100, 3 => 1000, etc
        let decPlaces = 2
        decPlaces = Math.pow(10, decPlaces)

        // Enumerate number abbreviations
        const abbrev = ["k", "m", "b", "t"]

        // Go through the array backwards, so we do the largest first
        for (let i = abbrev.length - 1; i >= 0; i--) {
          // Convert array index to "1000", "1000000", etc
          const size = Math.pow(10, (i + 1) * 3)

          // If the number is bigger or equal do the abbreviation
          if (size <= nb) {
            // Here, we multiply by decPlaces, round, and then divide by decPlaces.
            // This gives us nice rounding to a particular decimal place.
            nb = Math.round((nb * decPlaces) / size) / decPlaces

            // Handle special case where we round up to the next abbreviation
            if (nb == 1000 && i < abbrev.length - 1) {
              nb = 1
              i++
            }

            // Add the letter for the abbreviation
            nb += abbrev[i]

            // We are done... stop
            break
          }
        }

        return nb
      },
    }
    app.config.globalProperties.$string = stringModule
  },
}

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $string: IStringModule
  }
}
