import http from "@/http-common"

class LocalDiffuserDataService {
  createLocalDiffuserConnexion(ticId: number, establishmentId: number, headers: {}): Promise<any> {
    return http.post(
      `/auth/local-diffusers/create`,
      {
        tourism_information_center_id: ticId,
        establishment_id: establishmentId,
      },
      {
        headers: headers,
      }
    )
  }

  getLocalDiffuserSolicitations(localDiffuserId: number): Promise<any> {
    return http.get(`/auth/local-diffusers/solicitations/${localDiffuserId}`)
  }

  getFreemiumLogo(establishmentId: number, headers: {}): Promise<any> {
    return http.get(`/auth/freemium/logo/${establishmentId}`, { headers: headers })
  }

  getLocalDiffuserStatus(establishmentId: number): Promise<any> {
    return http.get(`/auth/establishments/${establishmentId}/open-form/last-state`)
  }

  refuseAdhesion(solicitationId: number): Promise<any> {
    return http.post(`/auth/local-diffusers/solicitations/refuse-adhesion`, { solicitation_id: solicitationId })
  }
}

export default new LocalDiffuserDataService()
