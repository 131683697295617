import type { IOnboardingStep } from "@/types/Onboarding.types"

const introduction: IOnboardingStep = {
  stepNumber: 1,
  actionTitle: "Allons y !",
  i18ncode: "onboarding/introduction.introduction_presentation.action_button",
  slug: "presentation",
}

const freemiumIntroduction: IOnboardingStep = {
  stepNumber: 1,
  actionTitle: "Allons y !",
  i18ncode: "onboarding/freemium.introduction_presentation.action_button",
  slug: "inscription",
}

const etab: IOnboardingStep = {
  stepNumber: 1,
  actionTitle: "Voir les résultats",
  i18ncode: "onboarding/establishment.establishment_list_choice.action_button",
  slug: "establishment",
}

const result: IOnboardingStep = {
  stepNumber: 2,
  actionTitle: "Passer à l'action",
  i18ncode: "onboarding/analyse.results.action_button",
  slug: "analyse",
}

const account: IOnboardingStep = {
  stepNumber: 3,
  actionTitle: null,
  i18ncode: "action_button",
  slug: "howtocreatemyaccount",
}

const freemiumAccount: IOnboardingStep = {
  stepNumber: 2,
  actionTitle: null,
  i18ncode: "action_button",
  slug: "inscription",
}

const freemiumEstablishment: IOnboardingStep = {
  stepNumber: 3,
  actionTitle: null,
  i18ncode: "action_button",
  slug: "establishment",
}

// Séquence du onboarding pour les utilisateurs venant de l'espace pro
export const onboardingStepsPro: { [name: string]: IOnboardingStep } = {
  "1": introduction,
  "2": etab,
  "3": result,
  "4": account,
}

export const onboardingFreemium: { [name: string]: IOnboardingStep } = {
  "1": freemiumIntroduction,
  "2": freemiumAccount,
  "3": freemiumEstablishment,
}

const informations: IOnboardingStep = {
  stepNumber: 1,
  actionTitle: null,
  i18ncode: null,
  slug: "begin",
}

// Séquence du onboarding pour les utilisateurs venant d'autres sources'
export const onboardingSteps: { [name: string]: IOnboardingStep } = {
  "1": introduction,
  "2": informations,
  "3": result,
  "4": account,
}
