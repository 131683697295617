export const codePostalRegex = /^\d{2}[ ]?\d{2,3}$/
export const codePostalCARegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
export const emailRegex = /[^@\s]+@[^@\s]+\.[^@\s]+/

// 8 caractères + 1 majuscule + 1 minuscule + 1 chiffre + 1 caractère spécial
export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&\/:µ£\\_\-()~`^²}{\][|¤€é#çà°+ù§;,.<>])[A-Za-z\d@$!%*?&\/:µ£\\_\-()~`^²}{\][|¤€é#çà°+ù§;,.<>]{8,}$/

// Majuscule + minuscule
export const passwordContainsLetters = /^(?=.*[a-z])(?=.*[A-Z]).*$/

// Chiffre
export const passwordContainsNumber = /^(?=.*[0-9]).*$/

// Caractère spécial
export const passwordContainsSpecials = /^(?=.*[@$!%*?&\/:µ£\\_\-()~`^²}{\][|¤€é#çà°+ù§;,.<>])(?!.*["'=]).*$/

export const facebookRegex = /^https:\/\/((www\.)?|(m\.)?|(b-m\.)?)facebook\.com\/(profile.php?id=)?.*/
export const pinterestRegex = /^https:\/\/(www\.)?pinterest\.fr\/.*/
export const linkedinRegex = /^https:\/\/(www\.|fr\.)?linkedin\.com\/(in|company)\/.*/
export const youtubeRegex = /^https:\/\/(www\.)?youtube\.com\/(@|channel\/).*/
export const instagramRegex = /^https:\/\/(www\.)?instagram\.com\/.*/
export const twitterRegex = /^https:\/\/(www\.)?(twitter|x)\.com\/.*/

export enum RegexType {
  phone,
  codePostal,
  email,
  password,
  passwordContainsLetters,
  passwordContainsNumber,
  passwordContainsSpecials,

  facebookRegex,
  pinterestRegex,
  linkedinRegex,
  youtubeRegex,
  instagramRegex,
  twitterRegex,
}
