import type { App } from "vue"
import { loadLanguageAsync } from "../i18n"

export interface ITraductionPlugin {
  changeLang: (lang: string) => void
}

export default {
  install: (app: App) => {
    const traductionModule: ITraductionPlugin = {
      changeLang: function (lang: string) {
        loadLanguageAsync(lang)
      },
    }
    app.config.globalProperties.$traduction = traductionModule
  },
}

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $traduction: ITraductionPlugin
  }
}
