import http from "@/http-common"

class AuthDataService {
  /**
   * Login the app (activate token)
   * @param email
   * @param password
   * @returns
   */
  login(email: string, password: string): Promise<any> {
    return http.post(`/auth/login`, {
      email: email,
      password: password,
    })
  }

  /**
   * Logout the app (inactivate token)
   * @param headers
   * @returns
   */
  logout(headers: {}): Promise<any> {
    return http.get(`/auth/logout`, { headers: headers })
  }

  /**
   * Get current authenticated user by token
   * @param headers
   * @returns
   */
  me(headers: {}): Promise<any> {
    return http.get(`/auth/me`, {
      headers: headers,
    })
  }

  /**
   * Validate new user email
   * @param token
   * @returns
   */
  validate(token: string) {
    return http.get(`/auth/validate-user/` + token)
  }

  /**
   * Send new validation email
   * @param token
   * @returns
   */
  resendValidationEmail(email: string) {
    return http.post(`/auth/re-send-validation-mail`, {
      email: email,
    })
  }

  // FORGET PASSWORD
  /**
   * Send email to reset the forgotten password
   * @param email
   * @returns
   */
  forgetPasswordSendEmail(email: string): Promise<any> {
    return http.post(`/auth/forget-password/request`, {
      email: email,
    })
  }

  /**
   * Check if new user password token is valid
   * @param token
   * @param headers
   * @returns
   */
  forgetPasswordCheckToken(token: string, headers: {}): Promise<any> {
    return http.get(`/auth/forget-password/check-token/` + token, {
      headers: headers,
    })
  }

  /**
   * Reset the forgotten password
   * @param token
   * @param password
   * @param headers
   * @returns
   */
  resetPassword(token: string, password: string, headers: {}): Promise<any> {
    return http.post(
      `/auth/forget-password/reset`,
      {
        token: token,
        password: password,
      },
      {
        headers: headers,
      }
    )
  }

  // SETUP PASSWORD
  /**
   * Check if new user password token is valid
   * @param token
   * @param headers
   * @returns
   */
  setupPasswordCheckToken(token: string, headers: {}): Promise<any> {
    return http.get(`/auth/setup-password/check-token/` + token, {
      headers: headers,
    })
  }

  /**
   * Set new password for new user first connexion
   * @param token
   * @param password
   * @returns
   */
  setupPassword(token: string, password: string): Promise<any> {
    return http.post(`/auth/setup-password/set`, {
      token: token,
      password: password,
    })
  }
}

export default new AuthDataService()
