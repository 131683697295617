import type { App } from "vue"
import type { IConfirm } from "@/types/Modal.types"

export interface IModalPlugin {
  confirm: (confirm: IConfirm, success: () => void, failed: () => void) => void
}

export default {
  install: (app: App) => {
    const modalModule: IModalPlugin = {
      confirm(confirm: IConfirm, yes: () => void, no: () => void) {
        const confirmBox = document.getElementById("confirm")
        const titleBox = document.getElementById("confirmTitle")
        const messageBox = document.getElementById("confirmMessage")
        const inputYes = document.getElementById("confirmYes")
        const inputNo = document.getElementById("confirmNo")

        if (confirmBox) {
          titleBox!.innerHTML = confirm.title
          messageBox!.innerHTML = confirm.message
          inputYes!.innerHTML = confirm.yes
          inputYes!.addEventListener("click", () => {
            confirmBox.classList.remove("active")
            yes()
          })
          inputNo!.innerHTML = confirm.no
          inputNo!.addEventListener("click", () => {
            confirmBox.classList.remove("active")
            no()
          })
          confirmBox.classList.add("active")
        }
      },
    }
    app.config.globalProperties.$modal = modalModule
  },
}

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $modal: IModalPlugin
  }
}
