import moment from "moment"
import type { App } from "vue"

export interface IDatePlugin {
  format: (date: string) => string
  formatForApi: (date: string) => string
  formatTime: (date: string) => string
}

export default {
  install: (app: App) => {
    const dateModule: IDatePlugin = {
      format: function (date: string) {
        const d = moment(date)
        return d.format("DD/MM/YYYY")
      },
      formatTime: function (date: string) {
        const d = moment(date)
        return d.format("HH:mm - DD/MM/yyyy")
      },
      formatForApi: function (date: string) {
        const d = moment(date)
        return d.format("YYYY-MM-DD")
      },
    }
    app.config.globalProperties.$date = dateModule
  },
}

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $date: IDatePlugin
  }
}
