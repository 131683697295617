import type { App } from "vue"

export interface IListPlugin {
  toggle: (array: any[], item: any) => any[]
  include: (array: any[], item: any) => boolean
}

export default {
  install: (app: App) => {
    const arrayModule: IListPlugin = {
      toggle: function (array: any[], item: any) {
        const index = array.indexOf(item)
        if (index === -1) {
          array.push(item)
        } else {
          array.splice(index, 1)
        }
        return array
      },
      include: function (array: any[], item: any) {
        const index = array.indexOf(item)
        if (index === -1) {
          return false
        } else {
          return true
        }
      },
    }
    app.config.globalProperties.$list = arrayModule
  },
}

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $list: IListPlugin
  }
}
