import type { IOnboardingStep } from "@/types/Onboarding.types"
import type { App } from "vue"
import type { RouteLocationNormalizedLoaded } from "vue-router"
import { onboardingSteps, onboardingStepsPro, onboardingFreemium } from "@/static/onboarding/OnboardingSteps"

export interface IOnboardingPlugin {
  getSequence: (route: RouteLocationNormalizedLoaded) => {
    [name: string]: IOnboardingStep
  }
}

export default {
  install: (app: App) => {
    const onboardingModule: IOnboardingPlugin = {
      /**
       * Return the onboarding sequence by the founded user source
       */
      getSequence: function (route: RouteLocationNormalizedLoaded) {
        const source = route.query.utm_source // hubo ou hubo_freemium
        const content = route.query.utm_content // 123456 (token du contact)
        const medium = route.query.utm_medium // contact

        const validation = source !== null && content != null && medium !== null

        if (validation) {
          return source === "hubo_freemium" ? onboardingFreemium : onboardingStepsPro
        } else {
          // Other signifirait une arrivée depuis la landing page PH
          return onboardingSteps
        }
      },
    }

    app.config.globalProperties.$onboarding = onboardingModule
  },
}

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $onboarding: IOnboardingPlugin
  }
}
