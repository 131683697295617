import http from "@/http-common"

class UberallDataService {
  /**
   * Get uberall status (up, down)
   * @param headers
   * @returns
   */
  getStatus(headers: {}): Promise<any> {
    return http.get(`/uberall/status`, { headers: headers })
  }

  /**
   * Synchronize all establishment informations in all diffusers
   * @param establishmentId
   * @param headers
   * @returns
   */
  syncLocation(establishmentId: number, headers: {}): Promise<any> {
    return http.get(`auth/uberall/establishments/sync/` + establishmentId, { headers: headers })
  }
}

export default new UberallDataService()
