import { ConfigurationState } from "@/static/Configuration"
import { useAuthenticationStore } from "@/stores/authentication"

export default () => {
  const auth = useAuthenticationStore()
  const isConfigured = auth.user?.account.configured
  const state = auth.user?.account.configuration_state

  if (!isConfigured) {
    if (state === ConfigurationState.START_FREEMIUM) {
      return {
        success: false,
        path: "/welcome/free",
      }
    } else {
      return {
        success: false,
        path: "/welcome",
      }
    }
  }
  return {
    success: true,
  }
}
