// import { nextTick } from 'vue'
import { createI18n, type I18n, type I18nOptions } from "vue-i18n"
import axios from "axios"
import http from "@/http-common"

const datetimeFormats: I18nOptions["datetimeFormats"] = {
  en: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
    },
  },
  fr: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    },
  },
}

const options = {
  locale: import.meta.env.VITE_APP_I18N_LOCALE || "en",
  fallbackLocale: import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE || "en",
  datetimeFormats: datetimeFormats,
}

export const i18n = createI18n(options)

const loadedLanguages: string[] = []

function setI18nLanguage(lang: string) {
  i18n.global.locale = lang
  axios.defaults.headers.common["Accept-Language"] = lang
  const html = document.querySelector("html")
  if (html) {
    html.setAttribute("lang", lang)
  }
  return lang
}

function getJson(): Promise<any> {
  return http.get(`/json`)
}

export async function loadLanguageAsync(lang: string) {
  // If the same language
  if (i18n.global.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  const fromExternalUrl = false

  if (fromExternalUrl) {
    const messages = await getJson()
    i18n.global.setLocaleMessage(lang, messages.data[lang])
    return setI18nLanguage(lang)
  } else {
    const messages = await import(`./locales/${lang}.json`)
    i18n.global.setLocaleMessage(lang, messages.default)
    return setI18nLanguage(lang)
  }
}
