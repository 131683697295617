import HuboDataService from "@/services/HuboDataService"
import TrackingDataService from "@/services/TrackingDataService"
import { FreemiumTrafficCode } from "@/static/Tracking"
import type { IFreemiumOnboardingTraffic, IFreemiumConvertTraffic } from "@/types/Tracking.types"
import { defineStore } from "pinia"
import { useEstablishmentStore } from "./establishment"

export const useTrackingStore = defineStore("tracking", {
  state: () => ({
    currentTicLogoUrl: null,
  }),

  actions: {
    createFreemiumTraffic(code: FreemiumTrafficCode, accountId?: number) {
      if (
        [
          FreemiumTrafficCode.FREEMIUM_CONVERSION_RDV_MADE,
          FreemiumTrafficCode.FREEMIUM_CONVERSION_REQUEST_CONTACT,
        ].includes(code)
      ) {
        // Traffic freemium conversion premium
        const establishmentStore = useEstablishmentStore()
        TrackingDataService.createFreemiumTraffic({
          establishment_id: establishmentStore.currentEstablishment.id,
          step_code: code,
          account_id: accountId,
        } as IFreemiumConvertTraffic)
      } else {
        // Traffic freemium pendant l'onboarding
        if (this.router.currentRoute.value.query.utm_content) {
          HuboDataService.getContact(this.router.currentRoute.value.query.utm_content.toString()).then((res) => {
            if (res.data.success) {
              const response = res.data.data
              TrackingDataService.createFreemiumTraffic({
                hubo_contact_token: response.token,
                hubo_account_id: response.account_id,
                step_code: code,
                account_id: code === FreemiumTrafficCode.FREEMIUM_ACCOUNT_CREATED && accountId ? accountId : null,
              } as IFreemiumOnboardingTraffic)

              this.currentTicLogoUrl = response.account_logo_url
            }
          })
        }
      }
    },

    discoverPremium() {
      TrackingDataService.discoverPremium()
    }
  },
})
