import type { App } from "vue"
import http from "@/http-common"
import type { MapboxFeature } from "@/types/Mapbox.types"
import { MapboxResponseType } from "@/static/Mapbox"

export interface IMapboxPlugin {
  getTownsFromInput: (pattern: string, type: MapboxResponseType, callback: (items: MapboxFeature[]) => void) => void
}

export default {
  install: (app: App) => {
    const mapboxModule: IMapboxPlugin = {
      /**
       * Get the list of towns from search pattern
       * @param pattern
       * @param type
       * @param callback
       * @param countries
       */
      getTownsFromInput: function (
        pattern: string,
        type: MapboxResponseType,
        callback: (items: MapboxFeature[]) => void,
        countries = ['FR', 'CH']
      ) {
        if (pattern === "") {
          const items = [] as String[]
          callback(items)
        } else {

          if (!Array.isArray(countries)) {
            countries = [countries]
          }

          let url =
            "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
            pattern +
            ".json?limit=10&autocomplete=true&types=" +
            type +
            "&access_token=" +
            import.meta.env.VITE_MAPBOX_API_KEY

          countries.forEach(country => {
            url += '&country=' + country
          })

          http.get(url).then((res) => {
            callback(res.data.features)
          })
        }
      },
    }

    app.config.globalProperties.$mapbox = mapboxModule
  },
}

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $mapbox: IMapboxPlugin
  }
}

/**
 * Set the current selected town
 * @param town
 */
// setCurrentTown(town: MapboxFeature) {
//     this.currentTown = town.text
//     this.foundedTowns = []

//     let establishment = this.onboardingStore.establishment
//     establishment.address.city = this.currentTown
//     this.onboardingStore.setEstablishment(establishment)
// },
