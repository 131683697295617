<template>
  <div></div>
</template>
<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
  name: "LoadHotjar",
  data() {
    return {
      env: import.meta.env.MODE
    }
  },
  methods: {
    loadHotjar() {
      let script = document.createElement("script")
      script.setAttribute("src", "/hotjar.js")
      script.setAttribute("id", "hotjar-script")
      document.head.appendChild(script)
    },
  },
  created() {
    if (this.env === 'production') {
      this.loadHotjar()
    }
  }
})
</script>
