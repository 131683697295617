import type { DayOfWeek } from "@/types/Establishment.types"

// style for ListingStatus
export const listingStateStyle = {
  successStyle: { class: "success", icon: "IconCheckCircle", color: "!text-success-500" },
  syncInProgressStyle: { class: "success", icon: "IconAlmostInSync", color: "!text-success-500" },
  warningStyle: { class: "warning", icon: "IconDotsHorizontal", color: "!text-tertiary-500" },
  errorStyle: { class: "error", icon: "IconXCircle", color: "!text-error-500" },
  pauseStyle: { class: "gray", icon: "IconHourglass", color: "!text-gray-200" },
}

export enum EstablishmentProductPlan {
  FREEMIUM = "FREEMIUM",
  FREEMIUM_TRIAL = "FREEMIUM_TRIAL",
  ESSENTIAL = "ESSENTIAL",
  ADVANCED = "ADVANCED",
  PRO = "PRO",
}

export const freemiumPlans: String[] = [EstablishmentProductPlan.FREEMIUM, EstablishmentProductPlan.FREEMIUM_TRIAL]

export enum AttributeType {
  BOOL = "BOOL",
  URL = "Single URL",
  URLS = "Multiple URLs",
  ENUM = "ENUM",
  REPEATED_ENUM = "REPEATED_ENUM",
}

export enum CommunicationCode {
  PHONE = "PHONE",
  EMAIL = "EMAIL",
  CELLPHONE = "CELLPHONE",
  WEBSITE = "WEBSITE",
  FACEBOOK = "FACEBOOK",
  INSTAGRAM = "INSTAGRAM",
  YOUTUBE = "YOUTUBE",
  PINTEREST = "PINTEREST",
  LINKEDIN = "LINKEDIN",
  TWITTER = "TWITTER",
}

export const daysOfWeeks: DayOfWeek[] = [
  {
    dayOfWeek: 0,
    title: "Lundi",
    closed: true,
    hours: [],
  },
  {
    dayOfWeek: 1,
    title: "Mardi",
    closed: true,
    hours: [],
  },
  {
    dayOfWeek: 2,
    title: "Mercredi",
    closed: true,
    hours: [],
  },
  {
    dayOfWeek: 3,
    title: "Jeudi",
    closed: true,
    hours: [],
  },
  {
    dayOfWeek: 4,
    title: "Vendredi",
    closed: true,
    hours: [],
  },
  {
    dayOfWeek: 5,
    title: "Samedi",
    closed: true,
    hours: [],
  },
  {
    dayOfWeek: 6,
    title: "Dimanche",
    closed: true,
    hours: [],
  },
]
