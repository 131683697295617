export enum ConfigurationState {
  START_ONBOARDING = "START_ONBOARDING",
  START_CONNEXION = "START_CONNEXION",
  GOOGLE_CONNECTED = "GOOGLE_CONNECTED",
  START_ESTABLISHMENT = "START_ESTABLISHMENT",
  START_LISTING = "START_LISTING",
  START_FREEMIUM = "START_FREEMIUM",
}

export const configurationStateRouteName: { [name: string]: string } = {
  [ConfigurationState.START_ONBOARDING]: "config-begin",
  [ConfigurationState.START_CONNEXION]: "config-connect",
  [ConfigurationState.START_ESTABLISHMENT]: "config-establishment",
  [ConfigurationState.START_LISTING]: "config-listing",
  [ConfigurationState.START_FREEMIUM]: "config-freemium",
}
