import http from "@/http-common"

class SubscriptionsDataService {
  /**
   * Get all the subscriptions for the current user
   * @returns
   */
  getSubscriptions(headers: {}): Promise<any> {
    return http.get(`/auth/subscriptions`, { headers: headers })
  }
}

export default new SubscriptionsDataService()
