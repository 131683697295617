import http from "@/http-common"

class HuboDataService {
  /**
   * Get a hubo contact
   * @param string
   * @returns
   */
  getContact(token: string): Promise<any> {
    return http.get(`/hubo/contact/` + token)
  }
}

export default new HuboDataService()
