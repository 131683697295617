// Vue
import App from "./App.vue"
import router from "./router"
import eventBus from "@/plugins/EventBus"
import { createApp, markRaw } from "vue"
import { i18n } from "@/i18n"
import * as Sentry from "@sentry/vue"

// Stores
import { createPinia } from "pinia"
import piniaPersist from "pinia-plugin-persist"

// Plugin
import StringPlugin from "@/plugins/StringPlugin"
import OnboardingPlugin from "@/plugins/OnboardingPlugin"
import MapboxPlugin from "@/plugins/MapboxPlugin"
import ModalPlugin from "@/plugins/ModalPlugin"
import WaitingPlugin from "@/plugins/WaitingPlugin"
import DatePlugin from "@/plugins/DatePlugin"
import ListPlugin from "@/plugins/ListPlugin"
import FormPlugin from "@/plugins/FormPlugin"
import AlertPlugin from "@/plugins/AlertPlugin"
import TraductionPlugin from "@/plugins/TraductionPlugin"
import ReloadPlugin from "@/plugins/ReloadPlugin"
import DiffuserPlugin from "@/plugins/DiffuserPlugin"

// Style
import "./assets/main.scss"

// App
const pinia = createPinia()
pinia.use(piniaPersist)
pinia.use(({ store }) => {
  store.router = markRaw(router)
})

const app = createApp(App)
const env = import.meta.env.VITE_ENV

if (env === "dev") {
  app.config.devtools = true
}

if (env === "production") {
  Sentry.init({
    app,
    dsn: "https://38547feceeda47b5828113532476a957@o59553.ingest.sentry.io/4504927407177728",
    integrations: [],
    tracesSampleRate: 1.0,
  })
}

app.config.globalProperties.$bus = eventBus
app.use(i18n)
app.use(pinia)
app.use(router)
app.use(StringPlugin)
app.use(OnboardingPlugin)
app.use(MapboxPlugin)
app.use(ModalPlugin)
app.use(WaitingPlugin)
app.use(DatePlugin)
app.use(ListPlugin)
app.use(FormPlugin)
app.use(AlertPlugin)
app.use(TraductionPlugin)
app.use(ReloadPlugin)
app.use(DiffuserPlugin)

app.mount("#app")
