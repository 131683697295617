import type { App } from "vue"

export interface IWaitingPlugin {
  simple: (callback: { (): void; (): void }, delay?: number) => void
}

export default {
  install: (app: App) => {
    const waitingModule: IWaitingPlugin = {
      simple: function (callback: { (): void; (): void }, delay?: number) {
        let timer: ReturnType<typeof setTimeout>
        timer = setTimeout(
          () => {
            callback()
            clearTimeout(timer)
          },
          delay ? delay : 100
        )
      },
    }
    app.config.globalProperties.$waiting = waitingModule
  },
}

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $waiting: IWaitingPlugin
  }
}
