import http from "@/http-common"

class PushDataService {
  store(subscription: {}): Promise<any> {
    return http.post(`/auth/push-subscription`, subscription)
  }

}

export default new PushDataService()
