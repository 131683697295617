export enum SolicitationType {
  CMAJ = "CMAJ",
  CADH = "CADH",
  INDIV = "INDIVIDUAL_FORM",
  DEFAULT = "DEFAULT",
}

export enum SolicitationState {
  SENT = "SENT", // mail parti au pro depuis l'OT (le pro l'a reçu)
  ANSWERED = "ANSWERED", // répondu
  SENT_APIDAE = "SENT_APIDAE", // donnée envoyée apidae (validé par l'OT)
  DRAFT = "DRAFT", // brouillon
  TREATED = "TREATED", // vu par l'OT
  SAVING = "SAVING", // en cours d'enregistrement -> bloquer l'accès aux actions
  SAVING_DRAFT = "SAVING_DRAFT", // status temporaire durant le temps d'enregistrement, même action que SAVING
}
