import { useAuthenticationStore } from "../stores/authentication"

export default () => {
  const auth = useAuthenticationStore()
  const hasFeature = auth.user?.access_features.find((feature) => feature.code === "INFORMATION") !== undefined

  if (!hasFeature) {
    return {
      success: false,
      path: "/error",
    }
  }
  return {
    success: true,
  }
}
