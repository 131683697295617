import { defineStore } from "pinia"

export const useAppStore = defineStore("app", {
  state: () => ({
    lang: import.meta.env.VITE_APP_I18N_LOCALE || "en",
    mediaProgress: 0 as number,
    mediaLoading: false as boolean,

    showEmojiModal: false,
  }),

  actions: {
    setMediaLoading(val: boolean) {
      this.mediaLoading = val
    },

    setMediaProgress(n: number) {
      this.mediaProgress = n
    },
  },

  persist: {
    enabled: true,
  },
})
