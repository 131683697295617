import { useAccountStore } from "@/stores/account"
import { useAuthenticationStore } from "../stores/authentication"

export default () => {
  const auth = useAuthenticationStore()
  const hasFeature = auth.user?.access_features.find((feature) => feature.code === "REVIEW") !== undefined

  const account = useAccountStore()
  const isFreemium = account.isFreemium

  if (!isFreemium && (!auth.user?.reviews_access || !hasFeature)) {
    return {
      success: false,
      path: "/error",
    }
  }
  return {
    success: true,
  }
}
